// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var categoryTitle = Cx.cx([
      "text-black",
      "mt-10",
      "lg:text-4xl",
      "max-sm:mb-6.25",
      "max-sm:text-xl",
      "max-sm:leading-7"
    ]);

var categoryDescription = Cx.cx([
      "text-gray-700",
      "md:text-base",
      "md:mt-4",
      "md:leading-6",
      "max-sm:text-sm",
      "max-sm:leading-5"
    ]);

var categoryItemContainer = Cx.cx([
      "border",
      "border-solid",
      "border-gray-300",
      "rounded-sm",
      "shadow-md",
      "w-full",
      "flex",
      "justify-between",
      "mb-5",
      "cursor-pointer",
      "max-sm:flex-col",
      "xl:h-37.5",
      "md:h-50",
      "hover:shadow-lg",
      "hover:border-purple-400"
    ]);

var itemImageContainer = Cx.cx([
      "bg-gray-400",
      "flex",
      "items-center",
      "justify-center",
      "max-sm:h-45",
      "max-sm:px-29",
      "max-sm:py-11",
      "xl:min-w-37.5",
      "xl:px-8.5",
      "xl:py-7.25",
      "lg:min-w-37.5",
      "lg:px-8.5",
      "lg:py-8.5",
      "md:min-w-37.5",
      "md:px-8.5",
      "md:py-8",
      "md:rounded-l-sm",
      "lg:rounded-l-sm"
    ]);

var itemDetailContainer = Cx.cx([
      "w-full",
      "md:p-7.5",
      "px-5",
      "pt-3.75"
    ]);

var itemHeading = Cx.cx([
      "text-black",
      "font-semibold",
      "text-lg",
      "leading-6",
      "lg:mb-0",
      "max-sm:text-base"
    ]);

var itemDescription = Cx.cx([
      "text-gray-700",
      "leading-5",
      "overflow-hidden",
      "text-sm",
      "text-ellipsis",
      "max-w-55",
      "line-clamp-3",
      "max-sm:text-xs",
      "max-sm:leading-3.75",
      "max-sm:h-11.25",
      "max-sm:max-w-full",
      "md:h-10",
      "md:max-w-full",
      "md:line-clamp-2",
      "lg:h-15",
      "xl:h-10",
      "xl:max-w-full",
      "xl:line-clamp-2"
    ]);

var downloadButtonContainer = Cx.cx([
      "flex",
      "items-center",
      "justify-center",
      "max-sm:justify-start",
      "max-sm:px-5",
      "max-sm:py-5",
      "md:pr-7.5"
    ]);

var resourceImage = Cx.cx([
      "h-full",
      "w-auto",
      "max-w-20.5"
    ]);

var paginationContainer = Cx.cx([
      "flex",
      "justify-center",
      "items-center",
      "my-12.5"
    ]);

export {
  categoryTitle ,
  categoryDescription ,
  categoryItemContainer ,
  itemImageContainer ,
  itemDetailContainer ,
  itemHeading ,
  itemDescription ,
  downloadButtonContainer ,
  resourceImage ,
  paginationContainer ,
}
/* categoryTitle Not a pure module */
