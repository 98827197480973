// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardNavbarCss from "../../../styleguide/dashboard/components/navbar/DashboardNavbarCss.res.js";

function isSelectedCategory(itemName, selectedCategoryDetail) {
  if (selectedCategoryDetail !== undefined) {
    return selectedCategoryDetail.navigationName === itemName;
  } else {
    return false;
  }
}

function TopBar(props) {
  var selectedCategory = props.selectedCategory;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: Belt_Array.mapWithIndex(props.categories, (function (index, category) {
                            return JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx("a", {
                                              children: category.navigationName,
                                              href: category.slug === "all-resources" ? "/resources" : "/resources/" + category.slug
                                            }),
                                        className: Cx.cx([category.slug === selectedCategory ? Cx.cx([
                                                      DashboardNavbarCss.underlinedLinkContainer,
                                                      DashboardNavbarCss.underlinedLinkItem
                                                    ]) : Cx.cx([
                                                      DashboardNavbarCss.linkContainer,
                                                      DashboardNavbarCss.normalLinkItem
                                                    ])])
                                      }, category.navigationName + String(index));
                          })),
                    className: DashboardNavbarCss.barContainer
                  }),
              className: DashboardNavbarCss.barWrapper
            });
}

var Css;

var make = TopBar;

export {
  Css ,
  isSelectedCategory ,
  make ,
}
/* react/jsx-runtime Not a pure module */
