// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Link from "../../../styleguide/components/Link/Link.res.js";
import * as LineSeperator from "./LineSeperator.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as PopularResourcesCss from "./PopularResourcesCss.res.js";

function PopularResourceItem(props) {
  var __path = props.path;
  var path = __path !== undefined ? __path : "#";
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(Link.make, {
                      href: path,
                      children: JsxRuntime.jsx("p", {
                            children: props.title,
                            className: PopularResourcesCss.resourceItemTitle
                          })
                    }),
                JsxRuntime.jsx(LineSeperator.make, {})
              ]
            });
}

var Css;

var make = PopularResourceItem;

export {
  Css ,
  make ,
}
/* Link Not a pure module */
