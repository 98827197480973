// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var row = Cx.cx([
      "flex",
      "justify-between",
      "max-lg:mx-0!"
    ]);

var grid = Cx.cx([
      "lg:px-0",
      "max-md:px-4",
      "max-md:py-5",
      "lg:mt-10!",
      "mt-5!",
      "w-auto!",
      "lg:max-w-screen-xl!"
    ]);

var contentContainer = Cx.cx([
      "lg:px-0",
      "lg:max-w-md",
      "xl:px-0",
      "xl:min-w-4xl",
      "md:pl-15",
      "md:pr-5"
    ]);

var darkAdWrapper = Cx.cx(["lg:mt-12"]);

var sideColumn = Cx.cx([
      "ml-0",
      "xl:max-w-70!",
      "xl:px-0",
      "lg:max-w-72!",
      "lg:px-0"
    ]);

var filters = Cx.cx([
      "bg-gray-50",
      "rounded-sm",
      "h-20",
      "p-5",
      "flex",
      "flex-row",
      "flex-wrap",
      "justify-between",
      "mb-7",
      "max-sm:h-45",
      "max-sm:flex-col",
      "lg:h-auto"
    ]);

var searchFieldWrapper = Cx.cx([
      "xl:w-[61.25%]",
      "w-full",
      "md:w-[42.06%]"
    ]);

var searchField = Cx.cx([
      "text-sm",
      "pl-9"
    ]);

var categoryField = Cx.cx([
      "text-sm",
      "bg-white!"
    ]);

var notFoundContainer = Cx.cx([
      "flex",
      "flex-col",
      "justify-center",
      "items-center",
      "p-12"
    ]);

var reason = Cx.cx([
      "text-xl",
      "font-semibold",
      "text-black",
      "mb-2.5"
    ]);

var action = Cx.cx([
      "text-base",
      "text-gray-600"
    ]);

var actionControl = Cx.cx([
      "font-medium",
      "border-b-none"
    ]);

var container = "pb-20";

var searchIcon = "border-hidden";

var searchButton = "max-sm:w-full";

var logo = "mb-7";

export {
  row ,
  container ,
  grid ,
  contentContainer ,
  darkAdWrapper ,
  sideColumn ,
  filters ,
  searchFieldWrapper ,
  searchField ,
  searchIcon ,
  categoryField ,
  searchButton ,
  notFoundContainer ,
  logo ,
  reason ,
  action ,
  actionControl ,
}
/* row Not a pure module */
