// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as PopularResourcesCss from "./PopularResourcesCss.res.js";

function LineSeperator(props) {
  var __className = props.className;
  var className = __className !== undefined ? __className : "";
  return JsxRuntime.jsx("div", {
              className: Cx.cx([
                    PopularResourcesCss.separator,
                    className
                  ])
            });
}

var Css;

var make = LineSeperator;

export {
  Css ,
  make ,
}
/* react/jsx-runtime Not a pure module */
