// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "bg-gray-50",
      "rounded-sm",
      "p-5",
      "max-sm:mt-12",
      "md:px-7",
      "md:pt-6"
    ]);

var title = Cx.cx([
      "text-black",
      "md:text-lg",
      "text-base",
      "leading-5",
      "lg:mb-5"
    ]);

var separator = Cx.cx([
      "h-px",
      "w-full",
      "border-b",
      "border-dotted",
      "border-gray-200"
    ]);

var resourceItemTitle = Cx.cx([
      "text-gray-700",
      "font-medium",
      "leading-5",
      "my-2.5",
      "text-sm",
      "cursor-pointer"
    ]);

export {
  container ,
  title ,
  separator ,
  resourceItemTitle ,
}
/* container Not a pure module */
