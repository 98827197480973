// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../../api/Api.res.js";
import * as Col from "../../../styleguide/components/Grid/Col/Col.res.js";
import * as Row from "../../../styleguide/components/Grid/Row/Row.res.js";
import * as Form from "../../../styleguide/forms/Form/Form.res.js";
import * as Grid from "../../../styleguide/components/Grid/Grid.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as React from "react";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as Select from "../../../styleguide/forms/Select/Select.res.js";
import * as TopBar from "../top-bar/TopBar.res.js";
import * as Control from "../../../styleguide/components/Control/Control.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Cloudinary from "../../../libs/Cloudinary.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as SearchField from "../../../styleguide/forms/SearchField/SearchField.res.js";
import * as ResourceItem from "../../../models/ResourceItem.res.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as PopularResources from "../popular-resources/PopularResources.res.js";
import * as ResourceCategory from "../../../models/ResourceCategory.res.js";
import * as CategoryResources from "../category-resources/CategoryResources.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ResourcesLandingCss from "./ResourcesLandingCss.res.js";

function getDetails(slug, categories) {
  return Belt_Option.getExn((function (__x) {
                  return Js_array.find((function (category) {
                                return category.slug === slug;
                              }), __x);
                })(categories));
}

function buildImageUrl(src) {
  return Cloudinary.Url.build("Png", {
              hd: {
                TAG: "Width",
                _0: 120
              },
              tl: {
                hd: {
                  TAG: "Height",
                  _0: 130
                },
                tl: /* [] */0
              }
            }, Cloudinary.imageUrl(src));
}

var Helpers = {
  getDetails: getDetails,
  buildImageUrl: buildImageUrl
};

function ResourcesLanding$ResourcesLanding(props) {
  var totalPages = props.totalPages;
  var selectedCategory = props.selectedCategory;
  var resources = props.resources;
  var categories = props.categories;
  var initialState = React.useMemo((function () {
          return {
                  category: selectedCategory,
                  query: "",
                  resources: resources,
                  currentPage: 1,
                  totalPages: totalPages,
                  lastSearchQuery: ""
                };
        }), []);
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            if (action === "ResetFilters") {
              return {
                      TAG: "UpdateWithSideEffects",
                      _0: {
                        category: selectedCategory,
                        query: "",
                        resources: state.resources,
                        currentPage: 1,
                        totalPages: state.totalPages,
                        lastSearchQuery: state.lastSearchQuery
                      },
                      _1: (function (param) {
                          param.dispatch("FetchResources");
                        })
                    };
            } else {
              return {
                      TAG: "UpdateWithSideEffects",
                      _0: {
                        category: state.category,
                        query: state.query,
                        resources: state.resources,
                        currentPage: state.currentPage,
                        totalPages: state.totalPages,
                        lastSearchQuery: state.query
                      },
                      _1: (function (param) {
                          var dispatch = param.dispatch;
                          var state = param.state;
                          $$Promise.wait(Api.fetchResources(state.currentPage, state.query, state.category), (function (x) {
                                  if (x.TAG === "Ok") {
                                    return dispatch({
                                                TAG: "UpdateResources",
                                                _0: x._0
                                              });
                                  } else {
                                    return SentryLogger.error1({
                                                rootModule: "ResourcesLanding",
                                                subModulePath: {
                                                  hd: "ResourcesLanding",
                                                  tl: /* [] */0
                                                },
                                                value: "make",
                                                fullPath: "ResourcesLanding.ResourcesLanding.make"
                                              }, "ResourcesLanding::FetchResources::Error", [
                                                "Error",
                                                x._0
                                              ]);
                                  }
                                }));
                        })
                    };
            }
          }
          switch (action.TAG) {
            case "UpdateFilter" :
                var value = action._1;
                var newState;
                newState = action._0 === "Category" ? ({
                      category: value,
                      query: state.query,
                      resources: state.resources,
                      currentPage: state.currentPage,
                      totalPages: state.totalPages,
                      lastSearchQuery: state.lastSearchQuery
                    }) : ({
                      category: state.category,
                      query: value,
                      resources: state.resources,
                      currentPage: state.currentPage,
                      totalPages: state.totalPages,
                      lastSearchQuery: state.lastSearchQuery
                    });
                return {
                        TAG: "Update",
                        _0: newState
                      };
            case "UpdateResources" :
                var res = action._0;
                return {
                        TAG: "Update",
                        _0: {
                          category: state.category,
                          query: state.query,
                          resources: res.resources,
                          currentPage: state.currentPage,
                          totalPages: res.totalPages,
                          lastSearchQuery: state.lastSearchQuery
                        }
                      };
            case "Paginate" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          category: state.category,
                          query: state.query,
                          resources: state.resources,
                          currentPage: action._0,
                          totalPages: state.totalPages,
                          lastSearchQuery: state.lastSearchQuery
                        },
                        _1: (function (param) {
                            param.dispatch("FetchResources");
                          })
                      };
            
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(TopBar.make, {
                      categories: categories,
                      selectedCategory: selectedCategory
                    }),
                JsxRuntime.jsx(Grid.make, {
                      className: ResourcesLandingCss.grid,
                      children: JsxRuntime.jsxs(Row.make, {
                            className: ResourcesLandingCss.row,
                            children: [
                              JsxRuntime.jsxs(Col.make, {
                                    className: ResourcesLandingCss.contentContainer,
                                    md: 8,
                                    lg: 8,
                                    children: [
                                      JsxRuntime.jsxs(Form.make, {
                                            className: ResourcesLandingCss.filters,
                                            onSubmit: (function (param) {
                                                dispatch({
                                                      TAG: "Paginate",
                                                      _0: 1
                                                    });
                                              }),
                                            children: [
                                              JsxRuntime.jsx(SearchField.make, {
                                                    id: "resource-filters--query",
                                                    value: state.query,
                                                    placeholder: "Search resources",
                                                    className: ResourcesLandingCss.searchFieldWrapper,
                                                    inputClassName: ResourcesLandingCss.searchField,
                                                    iconClassName: ResourcesLandingCss.searchIcon,
                                                    onChange: (function ($$event) {
                                                        dispatch({
                                                              TAG: "UpdateFilter",
                                                              _0: "Query",
                                                              _1: $$event.target.value
                                                            });
                                                      })
                                                  }),
                                              JsxRuntime.jsx(Select.make, {
                                                    id: "resource-filters--category",
                                                    value: state.category,
                                                    className: ResourcesLandingCss.categoryField,
                                                    iconSize: "XS",
                                                    iconColor: "GrayText",
                                                    onChange: (function ($$event) {
                                                        dispatch({
                                                              TAG: "UpdateFilter",
                                                              _0: "Category",
                                                              _1: $$event.target.value
                                                            });
                                                      }),
                                                    children: Belt_Array.map(categories, (function (categoryItem) {
                                                            return JsxRuntime.jsx(Select.$$Option.make, {
                                                                        value: categoryItem.slug,
                                                                        children: categoryItem.navigationName
                                                                      }, categoryItem.slug);
                                                          }))
                                                  }),
                                              JsxRuntime.jsx(Button.make, {
                                                    size: "SM",
                                                    color: "Teal",
                                                    submit: true,
                                                    className: ResourcesLandingCss.searchButton,
                                                    buttonClassName: ResourcesLandingCss.searchButton,
                                                    children: "Search"
                                                  })
                                            ]
                                          }),
                                      state.resources.length !== 0 ? JsxRuntime.jsx(CategoryResources.make, {
                                              resources: state.resources,
                                              category: getDetails(selectedCategory, categories),
                                              currentPage: state.currentPage,
                                              totalPages: state.totalPages,
                                              onPageClick: (function (page) {
                                                  dispatch({
                                                        TAG: "Paginate",
                                                        _0: page
                                                      });
                                                })
                                            }) : JsxRuntime.jsxs("div", {
                                              children: [
                                                JsxRuntime.jsx("img", {
                                                      className: ResourcesLandingCss.logo,
                                                      src: buildImageUrl("static/empty-page")
                                                    }),
                                                JsxRuntime.jsx("div", {
                                                      children: "We couldn't find anything for \"" + (state.lastSearchQuery + "\""),
                                                      className: ResourcesLandingCss.reason
                                                    }),
                                                JsxRuntime.jsxs("div", {
                                                      children: [
                                                        "Try entering different words or ",
                                                        JsxRuntime.jsx(Control.AsLink.make, {
                                                              inline: true,
                                                              className: ResourcesLandingCss.actionControl,
                                                              onClick: (function (param) {
                                                                  dispatch("ResetFilters");
                                                                }),
                                                              children: "reset search"
                                                            })
                                                      ],
                                                      className: ResourcesLandingCss.action
                                                    })
                                              ],
                                              className: ResourcesLandingCss.notFoundContainer
                                            })
                                    ]
                                  }),
                              JsxRuntime.jsx(Col.make, {
                                    className: ResourcesLandingCss.sideColumn,
                                    md: 4,
                                    lg: 4,
                                    children: JsxRuntime.jsx(PopularResources.make, {
                                          popular: props.popular
                                        })
                                  })
                            ]
                          })
                    })
              ],
              className: ResourcesLandingCss.container
            });
}

var ResourcesLanding = {
  Css: undefined,
  make: ResourcesLanding$ResourcesLanding
};

function ResourcesLanding$default(props) {
  var props$1 = props.props;
  return JsxRuntime.jsx(ResourcesLanding$ResourcesLanding, {
              categories: Belt_Array.map(props$1.categories, ResourceCategory.fromJs),
              popular: Belt_Array.map(props$1.popular, ResourceItem.fromJs),
              resources: Belt_Array.map(props$1.resources, ResourceItem.fromJs),
              selectedCategory: props$1.selectedCategory,
              totalPages: props$1.totalPages
            });
}

var $$default = ResourcesLanding$default;

export {
  Helpers ,
  ResourcesLanding ,
  $$default as default,
}
/* Api Not a pure module */
